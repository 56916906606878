const bankInfoDetails = {
  account_name: {
    show: true,
    value: 'PUG Marketing Management Co LLC',
  },
  swift_code: { show: true, value: 'ADCBAEAAXXX' },
  IBAN_number: {
    show: true,
    value: `AED：AE720030013375678920001<br/> USD：AE180030013375678920003`,
  },
  account_number: {
    show: true,
    value: `AED：13375678920001<br/> USD：13375678920003`,
  },
  bank_name: { show: true, value: 'Abu Dhabi Commercial Bank PJSC' },
  bank_address: { show: true, value: 'Al Riggah Road, Abu Dhabi, United Arab Emirates' },
}

module.exports = {
  bank_infos(currency) {
    let bank_infos = []
    bank_infos.push({
      title: 'common.field.beneficiaryAccountName',
      info: bankInfoDetails.account_name,
    })
    bank_infos.push({
      title: 'common.field.beneficiaryBankSwiftCode',
      info: bankInfoDetails.swift_code,
    })
    bank_infos.push({
      title: 'common.field.bankIbanNum',
      info: bankInfoDetails.IBAN_number,
    })
    bank_infos.push({
      title: 'common.field.beneficiaryAccountNumber',
      info: bankInfoDetails.account_number,
    })
    bank_infos.push({
      title: 'common.field.beneficiaryBankName',
      info: bankInfoDetails.bank_name,
    })
    bank_infos.push({
      title: 'common.field.BeneficiaryBankAddress',
      info: bankInfoDetails.bank_address,
    })
    return bank_infos
  },
}
