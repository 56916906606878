<template>
  <div class="content-box">
    <div class="inner">
      <div class="default-box">
        <div class="main">
          <div class="max" :class="{ hideStep: !showStep, cryptoStep: isShowCryptoStep }">
            <el-steps direction="vertical" v-if="showStep">
              <el-step icon="red"></el-step>
              <el-step icon="blue"></el-step>
              <el-step v-if="isShowCryptoStep" icon="blue"></el-step>
              <el-step v-if="isShowCryptoStep" icon="blue"></el-step>
              <el-step icon="red"></el-step>
            </el-steps>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultDeposit',
  props: {
    showStep: { type: Boolean, default: true },
    isDisabled: { type: Boolean, default: false },
    isCrypto: { type: Boolean, default: false }
  },
  computed: {
    isShowCryptoStep() {
      return this.isDisabled && this.isCrypto;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/template/deposit/defaultTemplate.scss';
</style>
